.header-wrapper {
  background-color: #191919;
  display: grid;
  grid-template-columns: minmax(250px, 1280px);
  justify-content: center;
  padding: 56px 30px;

  @media (max-width: 570px) {
    padding: 25px 30px;
  }

  .logo {
    src: url("../../assets/icons_site/logo.svg");
    @media (max-width: 560px) {
     max-width: 250px;
    }
  }

  .main-header {
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: center;
    column-gap: 16px;
  }

  .lang-position {
    justify-self: end;
  }
}
