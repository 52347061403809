form {
  display: flex;
  input {
    margin: 10px;
    width: 20%;
    padding: 5px;
    border-radius: 3px;
    border: none;
  }
  button {
    height: 30px;
    align-self: center;
  }
}