.reco-bcg {
  background: url(../../assets/recommendation/rec-bg.png) no-repeat;
  background-size: cover;
  padding: 110px 0 65px 0;

  .reco-title {
    display: grid;
    justify-items: center;
    margin-bottom: 65px;
  }
  .title-text {
    font-family: "Montserrat", "serif";
    font-size: 35px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0.38px;
    line-height: normal;
    color: #c74d39;
    display: block;
    text-align: center;
  }
  .icon-container {
    position: relative;
    h3 {
      height: 168px;
      width: 305px;
    }
  }
  .icon-text {
    color: #5c5a58;
    font-family: "Montserrat";
    font-size: 20px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    cursor: pointer;
    &:hover {
      color: #c74d39;
    }
  }
  .hide {
    opacity: 0;
    height: 0px;
    transition: 0.3s linear;

  }
  .show {
    opacity: 1;
    min-height: 168px;
    transition: 0.3s linear;

  }
  .text-course {
    font-family: "Montserrat", "serif";
    color: #ffffff;
    transition: 0.3s linear;
    position: absolute;
    top: 75px;
    width: inherit;
  }

  .icon-position {
    display: grid;
    grid-template-columns: minmax(200px, 1280px);
    justify-content: center;
  }

  .icon-wrap {
    justify-content: space-between;
    display: grid;
    grid-template-columns: repeat(3, 305px);
    @media (max-width: 960px) {
      grid-template-columns: 1fr;
      justify-content: center;
      row-gap: 30px;
    }
    div {
      display: grid;
      text-align: center;
      justify-items: center;
      row-gap: 25px;
    }
  }
}
