.row-currency {
  display: grid;
  grid-template-columns: minmax(50px, 320px) 1fr;
  border-top: 1px solid #c54b37;
  align-items: center;
  align-content: center;
  padding: 8px 16px;
  @media (max-width: 570px) {
    padding: 8px 8px;
    grid-column: 15px;
  }
  // justify-content: space-between;
  .name-currency {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    column-gap: 20px;
    @media (max-width: 570px) {
      grid-template-columns: 40px max-content;
    }

    img {
      @media (max-width: 570px) {
        width: 50px;
      }
    }
  }
  .info {
    max-width: 110px;
    .main {
      color: #ffffff;
      font-family: "Montserrat";
      font-size: 26px;
      font-weight: bold;
      letter-spacing: 0.27px;
      text-transform: uppercase;
      @media (max-width: 570px) {
        font-size: 16px;
      }
    }

    .country {
      @media (max-width: 400px) {
        text-overflow: ellipsis;
        overflow: hidden;
        width: 70px;
      }
    }
    .sub {
      color: #9e9e9e;
      font-family: "Montserrat Light";
      font-size: 11px;
      font-weight: 300;
      letter-spacing: 0.14px;
      display: flex;
      grid-column-gap: 5px;
      column-gap: 5px;
      align-items: baseline;

      &.up::before {
        content: ' ';
        display: inline-block;
        background: url(../../assets/icons_site/green-up.svg) no-repeat;
        background-size: contain;
        width: 11px;
        height: 6px;
        margin-right: 0px;
      }
      &.down::before {
        content: ' ';
        display: inline-block;
        background: url(../../assets/icons_site/red-up.svg) no-repeat;
        background-size: contain;
        width: 11px;
        height: 6px;
        margin-right: 0px;
      }
    }
    input {
      width: 80px;
      margin-top: 5px;
    }
  }

  .prices {
    display: grid;
    justify-content: space-between;
    grid-template-columns: minmax(100px, 270px) minmax(100px, 270px);
    @media (max-width: 570px) {
      grid-template-columns: repeat(2, 65px);
      column-gap: 5px;
    }
  }

  .controlsUpDown {
    grid-row: 1/3;
    align-self: center;
    display: grid;
    height: 100%;
    align-items: center;
    button {
      width: 15px;
      height: 23px;
      background: url(../../assets/icons_site/arrow.svg) no-repeat;
      background-size: 100%;
      border: none;
      
    }
    .upBtn {
      transform: rotate(90deg);
      position: relative;
    }
    .downBtn {
      transform: rotate(270deg);
      position: relative;
    }
  }
  .controlls {
    position: relative;
    grid-column: 3/3;
    display: grid;
    grid-template-columns: repeat(2,max-content);
    column-gap: 5px;
    button {
      border: none;
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
    .deleteBtn {
      background: url(../../assets/icons_site/delete.svg) no-repeat;
      background-size: 100%;
    }
    .editBtn {
      background: url(../../assets/icons_site/edit.svg) no-repeat;
      background-size: 100%;
    }
  }
}
