.footer-wrap {
  display: grid;
  grid-template-columns: minmax(250px, 1280px);
  justify-content: center;
  padding: 65px 30px;
  background-color: black;

  .footer {
    display: grid;
    grid-template-columns: 1fr;
    align-content: center;
    @media (max-width: 600px) {
      grid-template-columns: 1fr;
      justify-items: center;
      row-gap: 20px;
    }
  }
  .soc-icons {
    display: grid;
    grid-template-columns: repeat(4, 33px);
    column-gap: 35px;
    @media (max-width: 960px) {
      display: none;
    }
    img {
      filter: brightness(0.1) invert(1);
      opacity: 0.5;
      &:hover {
        filter: brightness(0) invert(1);
        opacity: 1;
      }
    } 
  }

  .logo-footer-wrap {
    justify-self: end;
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    column-gap: 75px;
    @media (max-width: 960px) {
      column-gap: 10px;
      justify-self: center;
    }
  }

  .foterArrow {
    transform: translateY(-20px);
    cursor: pointer;
    @media (max-width: 600px) {
      transform: translateY(0);
    }
  }
}
