.language {
  justify-self: end;
  color: white;
  display: grid;
  grid-template-columns: repeat(3, max-content);
  grid-template-rows: max-content;
  column-gap: 5px;

  div {
    display: grid;
    grid-template-columns: 1fr 2px;
    column-gap: 6px;
  }
  div:nth-child(3) > .lang-line:nth-child(2) {
    display: none;
  }
}
.lang-name {
  color: #9e9e9e;
  font-family: "Montserrat Light";
  font-size: 23px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0.25px;
  line-height: normal;
  cursor: pointer;
  @media (max-width: 570px) {
    font-size: 16px;
  }
}

.lang-line {
  width: 2px;
  background-color: #9e9e9e;
}

.active {
  color: #c74d39;
}

.disactiv {
  color: #9e9e9e;
}
