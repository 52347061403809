.table-currency {
  background: url(../assets/icons_site/main-img.png) no-repeat;
  background-size: 100%;
  background-color: black;
  display: grid;
  justify-content: center;
  grid-template-columns: minmax(250px, 1280px);
  padding: 56px 30px;
  @media (max-width: 570px) {
    padding: 56px 16px;
  }

  .tables {
    display: grid;
    grid-template-columns: minmax(460px, 860px) minmax(450px, 260px);
    justify-content: center;
    column-gap: 30px;

    @media (max-width: 960px) {
      grid-template-columns: 1fr;
      justify-items: center;
    }
  }

  main {
    max-width: 608px;
    display: grid;
    justify-items: center;
  }
  .main-table {
    border: 1px solid #c74d39;
    background-color: #232323de;
    padding-bottom: 22px;
    .title {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: 25px 23px;
      .name-title {
        color: #ffffff;
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 400;
      }
      .date-title {
        color: #ffffff;
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 1.56px;
      }
    }

    .columns {
      padding: 8px 16px;
      display: grid;
      grid-template-columns: minmax(50px, 320px) 1fr;
      .title-column {
        color: #9e9e9e;
        font-family: "Montserrat Light";
        font-size: 18px;
        font-weight: 300;
        letter-spacing: 0.2px;
        @media (max-width: 570px) {
          font-size: 12px;
        }

        &.name {
          width: 55%;
        }
        &.buy-sale {
          display: grid;
          justify-content: space-between;
          grid-template-columns: minmax(100px, 270px) minmax(100px, 270px);
          @media (max-width: 570px) {
            grid-template-columns: repeat(2, max-content);
            column-gap: 20px;
          }
        }
      }
    }
  }

  .otherCurrency {
    /* Style for "<Rectangle" */
    width: 260px;
    height: 42px;
    border: 1px solid #232323;
    background-color: #c74d39;
    color: #ffffff;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 700;
    margin: 30px 0;
  }

  .frame-wrap {
    display: grid;
    grid-template-columns: minmax(150px, 608px);
    row-gap: 40px;
    justify-items: center;
    margin-bottom: 20px;
    height: 720px;
    @media (max-width: 960px) {
      height: 820px;
    }
    .nbuGraph {
      width: 100%;
      max-height: 270px;
      background-color: #232323;
      border: 1px solid #c74d39;
      padding: 12px;
      @media (max-width: 960px) {
        height: fit-content;
      }
      .title {
        color: #ffffff;
        font-family: "Montserrat Light";
        font-size: 20px;
        font-weight: 400;
        position: absolute;
        margin: 0px 12px 24px 12px;
      }

      .label {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 15px;
        margin-bottom: 19px;
        .buy {
          color: #c74d39;
          font-family: "Montserrat Medium";
          font-size: 10px;
          font-weight: 500;
          letter-spacing: 0.08px;
          margin-right: 17px;
          &::before {
            content: url(../assets/icons_site/buy-dot.svg);
            padding-right: 4px;
          }
        }
        .sale {
          color: #58c439;
          font-family: "Montserrat Medium";
          font-size: 10px;
          font-weight: 500;
          letter-spacing: 0.08px;
          &::before {
            content: url(../assets/icons_site/sale-dot.svg);
            padding-right: 4px;
          }
        }
      }
    }
    .forexGraph {
      width: 100%;
      height: 410px;
      background-color: #232323;
      border: 1px solid #c74d39;
      padding: 12px;
      @media (max-width: 960px) {
        height: fit-content;
      }
      .list-forex {
        height: 160px;
        overflow: auto;
        color: #ffffff;
        font-family: "Montserrat";
        &::-webkit-scrollbar {
          width: 4px;
        }
    
        /* width */
        &:hover::-webkit-scrollbar {
          width: 4px;
        }
    
        /* Track */
        &::-webkit-scrollbar-track {
          border-radius: 3px;
        }
    
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #a3a3a3;
    
          border-radius: 5px;
        }
      }

      .title {
        color: #ffffff;
        font-family: "Montserrat Light";
        font-size: 20px;
        font-weight: 400;
        position: absolute;
        margin: 0px 12px 24px 12px;
      }

      .label {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
        margin-bottom: 5px;
        .buy {
          color: #c74d39;
          font-family: "Montserrat Medium";
          font-size: 10px;
          font-weight: 500;
          letter-spacing: 0.08px;
          margin-right: 17px;
          &::before {
            content: url(../assets/icons_site/buy-dot.svg);
            padding-right: 4px;
          }
        }
        .sale {
          color: #58c439;
          font-family: "Montserrat Medium";
          font-size: 10px;
          font-weight: 500;
          letter-spacing: 0.08px;
          &::before {
            content: url(../assets/icons_site/sale-dot.svg);
            padding-right: 4px;
          }
        }
      }
    }

  }
  iframe {
    @media (max-width: 960px) {
      width: 100%;
    }
  }
}
