.forex {
  .row-currency {
    display: flex;
    justify-content: space-around;
    //padding-left: 50px;
    .currency-name {
      width: calc(100% / 2);
      display: flex;
      justify-content: space-evenly;
    }
    .currency-rate {
      width: calc(100% / 2);
      display: flex;
      justify-content: center;
    }
  
    &:hover {
      background-color: rgb(40, 40, 40);
    }
    cursor: pointer;
    
  }
}