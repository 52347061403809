@font-face {
    font-family: "Montserrat Light";
    src: url("./assets//fonts/Montserrat-Light.ttf");
}

@font-face {
    font-family: "Montserrat";
    src: url("./assets//fonts/Montserrat-Medium.ttf");
}
body {
    filter: contrast(1.089);
}