.arrow-bcg {
	position: relative;
	padding: 0 30px;
	height: 0;

	.line-left {
		width: 100%;
		height: 4px;
		background-color: #c74d39;
		position: relative;
		&:before {
			content: url("../../assets/big-arrow/arrow-left.svg");
			position: absolute;
			top: -10px;
			left: -5px;
		}
	}

	.line-right {
		width: 100%;
		height: 4px;
		background-color: #c74d39;
		position: relative;
		&:after {
			content: url("../../assets/big-arrow/arrow-right.svg");
			position: absolute;
			top: -10px;
			right: -5px;
		
		}
	}
}


