.contacts-bcg {
  background-color: #191919;
  padding: 0 0 65px 0;

  .contact-position {
    display: grid;
    grid-template-columns: minmax(250px, 1280px);
    justify-content: center;
    padding: 0 30px;
  }

  .contacts-title-wrap {
    padding-top: 100px;
    display: grid;
    justify-items: center;
    margin-bottom: 65px;
    @media (max-width: 960px) {
      margin-bottom: 40px;
    }
  }

  .contacts-title {
    font-family: "Montserrat", "serif";
    font-size: 35px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0.38px;
    line-height: normal;
    color: #c74d39;
    display: block;
    text-align: center;
  }

  .phones {
    justify-content: space-between;
    display: grid;
    grid-template-columns: repeat(3, 280px);
    @media (max-width: 960px) {
      grid-template-columns: 1fr;
      justify-items: center;
      row-gap: 40px;
    }
  }

  .phone-wrap {
    display: grid;
    grid-template-columns: 45px minmax(100px, 250px);
    column-gap: 26px;
    span {
      font-family: "Montserrat";
      font-size: 21px;
      font-weight: 400;
      color: #ffffff;
      font-style: normal;
      letter-spacing: normal;
      line-height: normal;
      @media (max-width: 960px) {
        font-size: 16px;
      }
    }
    a {
      text-decoration: none;
      color: #ffffff;
      font-family: "Montserrat";
      font-size: 21px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: normal;
      line-height: normal;
      @media (max-width: 960px) {
        font-size: 16px;
      }
    }

    img {
      transform: translateY(-33px);
    }
  }
  .social-wrap {
    margin-top: 25px;
    span {
      color: #c74d39;
      font-family: Montserrat;
      font-size: 23px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: 0.25px;
      line-height: normal;
      @media (max-width: 960px) {
        font-size: 16px;
      }
    }
  }
  .soc-icons {
    margin-top: 33px;
    display: grid;
    grid-template-columns: repeat(4, 33px);
    column-gap: 35px;
    img {
      filter: brightness(0.1) invert(1);
      opacity: 0.5;
      &:hover {
        filter: brightness(0) invert(1);
        opacity: 1;
      }
    } 
  }
}

.sub-phone {
  width: max-content;
  a {
    display: block;
  }
}